var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "variant-color": "blue",
      "variant": "link",
      "is-disabled": !_vm.item.previousId
    },
    on: {
      "click": function click($event) {
        return _vm.onGetFollowUpNotes(_vm.item.previousId);
      }
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icon-chevron-left-filled.svg')
    }
  })], 1), _c('c-heading', {
    attrs: {
      "as": "h3",
      "size": "lg",
      "mx": "50px"
    }
  }, [_vm._v(" Tanggal " + _vm._s(_vm.getFormatDate(_vm.item.createdAt)) + " ")]), _c('c-button', {
    attrs: {
      "variant-color": "blue",
      "variant": "link",
      "is-disabled": !_vm.item.nextId
    },
    on: {
      "click": function click($event) {
        return _vm.onGetFollowUpNotes(_vm.item.nextId);
      }
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icon-chevron-right-filled.svg')
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "mt": [null, '30px'],
      "border-width": "1px",
      "border-color": "primary.400",
      "rounded": "lg",
      "px": ['20px', '45px'],
      "py": ['30px', '50px']
    }
  }, [_vm._l(_vm.steps, function (step, index) {
    return [_vm.stepIndex === index && !_vm.isEmptyItem ? _c('CardStep', {
      key: index,
      attrs: {
        "current": index + 1,
        "total": _vm.steps.length,
        "is-prev": index !== 0,
        "is-next": index < _vm.steps.length - 1
      },
      on: {
        "prev": function prev($event) {
          _vm.stepIndex--;
        },
        "next": function next($event) {
          _vm.stepIndex++;
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-weight": "700",
        "mb": "42px"
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]), step.type === 'nutritionAssessment' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Berat Badan/Weight (kg) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.weight) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Tinggi Badan/Height (cm) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.height) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" IMT/BMI (kg/m2): ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.bodyMassIndex) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Status Gizi ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.nutritionalStatus) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Kebutuhan Energi Bassal (kkal) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.basalEnergyNeeds) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Kondisi Keibuan ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.maternalCondition) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Tambahan kalori kondisi keibuan ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.maternalCalorieAddition) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Pertahankan Hal Ini / What to Maintain ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.maintain) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Perbaiki Hal InI / What to Improve ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionAssessment.improve) + " ")])], 1)], 1) : _vm._e(), step.type === 'physicalActivityHistory' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Jenis olahraga & durasi/Exercise type & duration ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.physicalActivityHistory.excercise) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Status Kecukupan Aktivitas Fisik ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.physicalActivityHistory.activityStatus) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Presentase Aktivitas Fisik ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.physicalActivityHistory.activityPercentage) + " ")])], 1)], 1) : _vm._e(), step.type === 'calorieMacroNutrientIntake' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Asupan Energi / Energy (kkal/kcal) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.energyIntake) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Kebutuhan Energi / Energy (kkal/kcal) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.energyNeeds) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Presentase Kecukupan Energi / Energy (kkal/kcal) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.energyAdequancy) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Asupan Protein sekarang (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.currentProteinIntake) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Kebutuhan Protein per Kg per Berat Badan ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.proteinNeeds) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Kebutuhan Asupan Protein ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.proteinIntakeNeeds) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Konstanta Kebutuhan Protein ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.proteinNeedsConstant) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" % Kecukupan Protein ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.proteinAdequancy) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Asupan Lemak / Fat (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.currentFatIntake) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Konstan Kebutuhan Lemak / Fat (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.fatNeedsConstant) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Kebutuhan Asupan Lemak / Fat (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.fatIntakeNeeds) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Presentase Kecukupan Lemak / Fat (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.fatAdequancy) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Asupan Karbohidrat / Carbohydrate (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.currentCarbohydrateIntake) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Kebutuhan Karbohidrat / Carbohydrate (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.carbohydrateIntakeNeeds) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Presentase Kecukupan Karbohidrat / Carbohydrate (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.carbohydrateAdequancy) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Asupan Serat / Fiber (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.fiberIntake) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Kebutuhan Serat / Fiber (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.fiberNeeds) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Presentase Kecukupan Serat / Fiber (gram) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.calorieMacroNutrientIntake.fiberAdequancy) + " ")])], 1)], 1) : _vm._e(), step.type === 'otherNote' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Catatan Lainnya dari Hasil Follow Up ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.otherNote) + " ")])], 1)], 1) : _vm._e(), step.type === 'primaryNutritionDiagnosis' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Problem ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.primaryNutritionDiagnosis.problem) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Etiology ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.primaryNutritionDiagnosis.etiology) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Sign & Symptoms ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.primaryNutritionDiagnosis.signSymptom) + " ")])], 1)], 1) : _vm._e(), step.type === 'nutritionIntervention' ? _c('c-box', [_c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Preskripsi Diet/Nutrition Prescription ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionIntervention.nutritionPrescription) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "18px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "5px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Target Pencapaian/Goals (SMART) ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(_vm.item.nutritionIntervention.goal) + " ")])], 1)], 1) : _vm._e(), step.type === 'nutritionMonitoring' ? _c('c-box', [_c('ul', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "mt": "20px",
        "pl": "20px"
      }
    }, _vm._l(_vm.item.nutritionMonitoring.checkParts, function (item, index_) {
      return _c('li', {
        key: 'part' + index_
      }, [_vm._v(" " + _vm._s(item) + " ")]);
    }), 0), _vm._l(_vm.item.nutritionMonitoring.indicators, function (item, index_) {
      return _c('c-form-control', {
        key: 'indicators' + index_,
        attrs: {
          "mb": "18px"
        }
      }, [_c('c-text', {
        attrs: {
          "mb": "5px",
          "color": "primary.400",
          "font-weight": "500",
          "font-size": "16px",
          "line-height": "24px"
        }
      }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('c-text', {
        attrs: {
          "color": "superDarkGray.900",
          "font-size": "18px",
          "line-height": "27px"
        }
      }, [_vm._v(" " + _vm._s(item.criteria) + " ")])], 1);
    })], 2) : _vm._e()], 1) : _vm._e()];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }