<template>
  <c-box>
    <c-flex
      align="center"
      justify="center"
    >
      <c-button
        variant-color="blue"
        variant="link"
        :is-disabled="!item.previousId"
        @click="onGetFollowUpNotes(item.previousId)"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-left-filled.svg')"
        />
      </c-button>
      <c-heading
        as="h3"
        size="lg"
        mx="50px"
      >
        Tanggal {{ getFormatDate(item.createdAt) }}
      </c-heading>
      <c-button
        variant-color="blue"
        variant="link"
        :is-disabled="!item.nextId"
        @click="onGetFollowUpNotes(item.nextId)"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-right-filled.svg')"
        />
      </c-button>
    </c-flex>
    <c-box
      :mt="[null, '30px']"
      border-width="1px"
      border-color="primary.400"
      rounded="lg"
      :px="['20px', '45px']"
      :py="['30px', '50px']"
    >
      <template v-for="(step, index) in steps">
        <CardStep
          v-if="stepIndex === index && !isEmptyItem"
          :key="index"
          :current="index + 1"
          :total="steps.length"
          :is-prev="index !== 0"
          :is-next="index < steps.length - 1"
          @prev="stepIndex--"
          @next="stepIndex++"
        >
          <c-text
            font-weight="700"
            mb="42px"
          >
            {{ step.title }}
          </c-text>
          <c-box v-if="step.type === 'nutritionAssessment'">
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Berat Badan/Weight (kg)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.weight }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Tinggi Badan/Height (cm)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.height }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                IMT/BMI (kg/m2):
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.bodyMassIndex }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Status Gizi
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.nutritionalStatus }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Kebutuhan Energi Bassal (kkal)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.basalEnergyNeeds }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Kondisi Keibuan
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.maternalCondition }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Tambahan kalori kondisi keibuan
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.maternalCalorieAddition }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Pertahankan Hal Ini / What to Maintain
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.maintain }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Perbaiki Hal InI / What to Improve
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionAssessment.improve }}
              </c-text>
            </c-form-control>
          </c-box>

          <c-box v-if="step.type === 'physicalActivityHistory'">
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Jenis olahraga & durasi/Exercise type & duration
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.physicalActivityHistory.excercise }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Status Kecukupan Aktivitas Fisik
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.physicalActivityHistory.activityStatus }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Presentase Aktivitas Fisik
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.physicalActivityHistory.activityPercentage }}
              </c-text>
            </c-form-control>
          </c-box>

          <c-box v-if="step.type === 'calorieMacroNutrientIntake'">
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Asupan Energi / Energy (kkal/kcal)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.energyIntake }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Kebutuhan Energi / Energy (kkal/kcal)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.energyNeeds }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Presentase Kecukupan Energi / Energy (kkal/kcal)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.energyAdequancy }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Asupan Protein sekarang (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.currentProteinIntake }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Kebutuhan Protein per Kg per Berat Badan
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.proteinNeeds }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Kebutuhan Asupan Protein
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.proteinIntakeNeeds }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Konstanta Kebutuhan Protein
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.proteinNeedsConstant }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                % Kecukupan Protein
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.proteinAdequancy }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Asupan Lemak / Fat (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.currentFatIntake }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Konstan Kebutuhan Lemak / Fat (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.fatNeedsConstant }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Kebutuhan Asupan Lemak / Fat (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.fatIntakeNeeds }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Presentase Kecukupan Lemak / Fat (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.fatAdequancy }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Asupan Karbohidrat / Carbohydrate (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.currentCarbohydrateIntake }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Kebutuhan Karbohidrat / Carbohydrate (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.carbohydrateIntakeNeeds }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Presentase Kecukupan Karbohidrat / Carbohydrate (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.carbohydrateAdequancy }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Asupan Serat / Fiber (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.fiberIntake }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Kebutuhan Serat / Fiber (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.fiberNeeds }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Presentase Kecukupan Serat / Fiber (gram)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.calorieMacroNutrientIntake.fiberAdequancy }}
              </c-text>
            </c-form-control>
          </c-box>

          <c-box v-if="step.type === 'otherNote'">
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Catatan Lainnya dari Hasil Follow Up
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.otherNote }}
              </c-text>
            </c-form-control>
          </c-box>

          <c-box v-if="step.type === 'primaryNutritionDiagnosis'">
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Problem
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.primaryNutritionDiagnosis.problem }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Etiology
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.primaryNutritionDiagnosis.etiology }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Sign & Symptoms
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.primaryNutritionDiagnosis.signSymptom }}
              </c-text>
            </c-form-control>
          </c-box>

          <c-box v-if="step.type === 'nutritionIntervention'">
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Preskripsi Diet/Nutrition Prescription
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionIntervention.nutritionPrescription }}
              </c-text>
            </c-form-control>
            <c-form-control mb="18px">
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                Target Pencapaian/Goals (SMART)
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.nutritionIntervention.goal }}
              </c-text>
            </c-form-control>
          </c-box>

          <c-box v-if="step.type === 'nutritionMonitoring'">
            <ul
              v-chakra
              mt="20px"
              pl="20px"
            >
              <li
                v-for="(item, index_) in item.nutritionMonitoring.checkParts"
                :key="'part' + index_"
              >
                {{ item }}
              </li>
            </ul>

            <c-form-control
              v-for="(item, index_) in item.nutritionMonitoring.indicators"
              :key="'indicators' + index_"
              mb="18px"
            >
              <c-text
                mb="5px"
                color="primary.400"
                font-weight="500"
                font-size="16px"
                line-height="24px"
              >
                {{ item.type }}
              </c-text>
              <c-text
                color="superDarkGray.900"
                font-size="18px"
                line-height="27px"
              >
                {{ item.criteria }}
              </c-text>
            </c-form-control>
          </c-box>
        </CardStep>
      </template>
    </c-box>
  </c-box>
</template>

<script>
import CardStep from '@/views/nutritionists/follow-up-notes/card-step'
import StepData from '../steps.js'
import moment from 'moment'
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FollowUpNoteHistory',
  components: { CardStep },
  data() {
    return {
      stepIndex: 0,
      steps: StepData.steps,
    }
  },
  computed: {
    ...mapState({
      item: (s) => s.nutriFollowUpNotes.followupNote,
    }),
    id() {
      return this.$route.query?.id
    },
    isEmptyItem() {
      return _.isEmpty(this.item)
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          if (_.isEmpty(val.nutritionAssessment)) {
            val.nutritionAssessment = {
              weight: 0,
              height: 0,
              bodyMassIndex: 0,
              nutritionalStatus: null,
              basalEnergyNeeds: null,
              maternalCondition: null,
              maternalCalorieAddition: null,
              maintain: null,
              improve: null,
            }
          }
          if (_.isEmpty(val.physicalActivityHistory)) {
            val.physicalActivityHistory = {
              excercise: null,
              activityStatus: null,
              activityPercentage: null,
            }
          }
          if (_.isEmpty(val.calorieMacroNutrientIntake)) {
            val.calorieMacroNutrientIntake = {
              energyIntake: null,
              energyNeeds: null,
              energyAdequancy: null,
              currentProteinIntake: null,
              proteinNeeds: null,
              proteinIntakeNeeds: null,
              proteinNeedsConstant: null,
              proteinAdequancy: null,
              currentFatIntake: null,
              fatNeedsConstant: null,
              fatIntakeNeeds: null,
              fatAdequancy: null,
              currentCarbohydrateIntake: null,
              carbohydrateIntakeNeeds: null,
              carbohydrateAdequancy: null,
              fiberNeeds: null,
              fiberAdequancy: null,
            }
          }
          if (_.isEmpty(val.primaryNutritionDiagnosis)) {
            val.primaryNutritionDiagnosis = {
              problem: null,
              etiology: null,
              signSymptom: null,
            }
          }
          if (_.isEmpty(val.nutritionIntervention)) {
            val.nutritionIntervention = {
              nutritionPrescription: null,
              goal: null,
            }
          }
          if (_.isEmpty(val.nutritionMonitoring)) {
            val.nutritionMonitoring = {
              checkParts: [],
              indicators: [],
            }
          }
        }
      },
    },
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getFollowUpNotesById(val)
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getFollowUpNotesById: 'nutriFollowUpNotes/getFollowUpNotesById',
    }),
    getFormatDate(date) {
      moment.locale('id')
      return moment(date).format('D MMMM yyyy')
    },
    onGetFollowUpNotes(id) {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, id: id },
      })
    },
  },
}
</script>
